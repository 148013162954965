import {
  ChakraProvider,
  Box,
  useConst,
  IconButton,
  Icon,
  extendTheme,
  Flex,
  useToast,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import IcecastMetadataPlayer from "icecast-metadata-player";
import { useCallback, useEffect, useState } from "react";
import {
  IoPlayCircleOutline,
  IoStopCircleOutline,
  IoVolumeHigh,
} from "react-icons/io5";
import "./rotation.css";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      "html, body, #root": {
        margin: 0,
        height: "100%",
      },
    },
  },
});

const server = "https://vinyl-backend.simonwhyte.dev";
const mountPoint = "vinyl";

const fullStream = `${server}/${mountPoint}`;

export const App = () => {
  const [state, setState] = useState("stopped");
  const toast = useToast({ position: "top" });
  const [volume, setVolume] = useState(100);

  const player = useConst(() => {
    return new IcecastMetadataPlayer(fullStream, {
      // onMetadata: (metadata: any) => {
      //   console.log(metadata);
      // },

      metadataTypes: [],
      onStop: () => {
        setState(player.state);
      },
      onPlay: () => {
        setVolume(player.audioElement.volume * 100);
        setState(player.state);
      },
      onLoad: () => {
        setState(player.state);
      },
      onRetry: () => {
        setState(player.state);
      },
      onError: (e) => {
        ((e) => {
          toast({
            status: "error",
            title: "Something went wrong :(",
            description: "Error: " + e,
          });
        })();
      },
    });
  });

  useEffect(() => {
    player.audioElement.volume = volume / 100;
  }, [volume]);

  useEffect(() => {
    console.log("State:", player.state);
  }, [player.state]);

  useEffect(() => {
    console.log("Playback method:", player.playbackMethod);
  }, [player.playbackMethod]);

  const isPlaying = state === "playing";
  const isLoading = state === "loading";

  const playPause = useCallback(() => {
    console.log("Attempt playPause:", player.state);
    if (!player.state) {
      player.play();
    }

    switch (player.state) {
      case "playing":
        player.stop();
        break;
      case "stopped":
        player.play();
        break;
    }
  }, [player]);

  return (
    <ChakraProvider theme={theme}>
      <Box
        textAlign="center"
        fontSize="xl"
        h="100%"
        background="url('/strange_days_bg.webp') no-repeat"
        backgroundSize="contain"
        backgroundPosition="center"
        position="relative"
      >
        {/* <Heading>Syte Vinyl</Heading> */}
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        <Box boxSize="100%">
          <Flex
            boxSize="100%"
            flexDirection="column"
            justifyContent="flex-end"
            align="center"
          >
            {state === "playing" && (
              <Slider
                width="40em"
                maxW="80%"
                aria-label="slider-ex-2"
                value={volume}
                min={0}
                max={100}
                onChange={setVolume}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box as={IoVolumeHigh} />
                </SliderThumb>
              </Slider>
            )}
            <IconButton
              margin="100px 100px"
              zIndex="1"
              _before={{
                content: `""`,
                borderRadius: "full",
                width: "200px",
                height: "200px",
                background: 'url("/vinyl_bg.png") no-repeat 0px 0px fixed',
                backgroundAttachment: "local",
                backgroundSize: "contain",
                zIndex: -1,
                // zIndex: "0",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                position: "absolute",
                display: "inline-block",
                // animation: isPlaying
                //   ? "rotation 2s infinite linear"
                //   : undefined,
              }}
              borderRadius="full"
              isLoading={isLoading}
              onClick={playPause}
              colorScheme="gray"
              // variant="ghost"
              icon={
                <Icon
                  as={isPlaying ? IoStopCircleOutline : IoPlayCircleOutline}
                />
              }
              aria-label={isPlaying ? "pause" : "play"}
            />
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
};
